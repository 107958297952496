@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  font-family: "Roboto", sans-serif !important;
}
.banner-sec {
  background-image: url(../img/banner.png);
  background-size: cover;
  background-position: center;
}
header.header_sec {
  position: absolute;
  width: 100%;
  background-color: transparent;
}
div#offcanvasNavbar-expand-md {
  margin-left: auto;
}

.navbar-nav {
  justify-content: end;
}
.banner-cont {
  padding: 90px 0 40px;
}
header.header_sec {
  position: absolute;
  width: 100%;
}
div#offcanvasNavbar-expand-md {
  margin-left: auto;
}
.navbar-nav {
  justify-content: end;
  align-items: center;
}
.navbar-nav .navbar-nav {
  margin-right: 15px;
}
ul.navbar-nav li a {
  color: #fff !important;
  font-size: 17px;
  line-height: 1.4;
  padding: 0 15px !important;
  font-weight: 400;
}
li.nav-item:hover a {
  color: #ce9d78;
}
.btn_primary {
  background: #cf9e75;
  font-size: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 1;
  text-transform: uppercase;
  justify-content: center;
  border-color: #cf9e75;
  min-height: 40px;
  min-width: 120px;
}
.home_cont {
  text-align: center;
  margin: 0 auto;
  max-width: 750px;
}
.home_cont h3 {
  color: #fff;
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: 300;
}
.search_cls {
  max-width: 550px;
  margin: 0 auto;
}
.search_inner_cls {
  display: flex;
}
.search_inner_cls .form-select {
  max-width: 120px;
  line-height: 1 !important;
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 17px;
  color: #000000;
}
.search_form_cls {
  width: 100%;
}
.search_form_cls .input_group {
  display: flex;
  align-items: center;
  position: relative;
}
.form-control {
  min-height: 40px;
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #d6d6d6 !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%) !important;
  border-radius: 7px !important;
}
.search-icon {
  background: #cf9e75;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  border-radius: 5px;
  position: absolute;
  right: 9px;
}
input,
select,
textarea,
.btn,
button,
a {
  outline: 0 !important;
  box-shadow: none !important;
}
.single_serv a {
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  color: #000000;
  font-size: 12px;
  text-decoration: none;
}
.service_inner_cls {
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single_serv {
  flex: 0 0 calc(100% / 6);
  padding: 0 5px;
}
span.categroy_name {
  margin-top: 10px;
  white-space: nowrap;
}
.heavy_service_btn {
  background: url(../img/heavy_service.png) !important;
  background-size: cover !important;
  background-position: center;
}
.service_btn {
  background: url(../img/soft-service.png) !important;
  background-size: cover !important;
  background-position: center;
}
.serv-btn {
  display: flex;
  justify-content: center;
}
.service_def {
  margin: 30px 0;
}
.serv-btn .service_btn {
  margin-right: 15px;
  min-width: 160px;
  display: flex;
  align-items: center;
  padding: 7px;
  border: 0 !important;
  border-radius: 10px;
}
.serv-btn .service_btn .serv-btn-icon {
  display: block;
  text-align: center;
  margin: 0 auto;
}
span.service_btn_border {
  width: 100%;
  border: solid 2px #ce9d78;
  border-radius: 10px;
  padding: 15px;
}
span.serv-btn-name {
  color: #000;
  margin-top: 10px;
  display: block;
  line-height: 1.2;
  font-size: 15px;
}
button.heavy_service_btn {
  min-width: 160px;
  display: flex;
  align-items: center;
  padding: 7px;
  border: 0 !important;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
}
.slider_banner {
  position: relative;
  padding-bottom: 25%;
  overflow: hidden;
  border-radius: 10px;
}

.slider_banner img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: unset;
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.owl-carousel .owl-nav div {
  position: absolute;
  top: calc(50% - 17.5px);
  font-size: 0 !important;
  background: url(../img/arrow-left.png) !important;
  height: 35px;
  width: 35px;
  outline: 0 !important;
  border-radius: 50% !important;
  background-repeat: no-repeat !important;
  left: 20px;
  background-position: center !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: #d29d7b !important;
  border: solid 2px #fff;
  box-shadow: 0 0 5px rgb(24 24 24 / 15%) !important;
}
.owl-carousel .owl-nav .owl-next {
  right: 20px !important;
  left: auto !important;
  background: url(../img/arrow-right.png) !important;
  outline: 0 !important;
  border-radius: 50% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: #d29d7b !important;
  border: solid 2px #fff;
  box-shadow: 0 0 5px rgb(24 24 24 / 15%) !important;
}
.owl-theme .owl-nav {
  margin: 0 !important;
}
.heavy_badge {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #48afde;
  padding: 5px 10px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  box-shadow: 0 3px 6px hsl(0deg 0% 0% / 15%);
  border-radius: 10px 0 10px 0;
  display: block;
  line-height: 1;
  text-align: center;
}
.offline_badge {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #f66710;
  padding: 5px 10px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  box-shadow: 0 3px 6px hsl(0deg 0% 0% / 15%);
  border-radius: 10px 0 10px 0;
  display: block;
  line-height: 1;
  text-align: center;
}
.soft_badge {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #ff709b;
  padding: 5px 10px;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  box-shadow: 0 3px 6px hsl(0deg 0% 0% / 15%);
  border-radius: 10px 0 10px 0;
  display: block;
  line-height: 1;
  text-align: center;
}
section.offer-sec {
  background: #f8fafc;
  padding: 30px 0;
  margin: 30px 0;
}

.offfer_banner {
  position: relative;
  padding-bottom: 50%;
  overflow: hidden;
  border-radius: 10px;
}

.offfer_bannerBottom {
  position: relative !important;
  padding-bottom: 25% !important;
  overflow: hidden !important;
  border-radius: 10px !important;
}

.offfer_banner img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.offfer_bannerBottom img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
  height: 100%;
}
.featured_sec {
  margin: 30px 0;
}
.title_cls {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
}
.heading_h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.view_all a {
  color: #000;
  text-decoration: none;
  font-size: 16px;
  transition: ease-in-out 0.3s;
}

.view_all a:hover {
  color: #ce9d78;
}
.categroy_slider_inner {
  background: #f8fafc;
  border-radius: 10px;
  overflow: hidden;
}
.categroy_slider_img {
  position: relative;
  padding-bottom: 70%;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}
.categroy_slider_img img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.categroy_slider_img .soft_badge {
  border-radius: 10px 0 0 0;
}
.categroy_slider_img .heavy_badge {
  border-radius: 10px 0 0 0;
}
.categroy_slider_img .offline_badge {
  border-radius: 10px 0 0 0;
}
.categroy_prd_cont {
  padding: 15px;
}
.categroy_prd_cont h4 {
  font-size: 17px !important;
  font-weight: 400;
  margin-bottom: 7px;
  /*white-space: nowrap;*/
  overflow: hidden;
  text-overflow: ellipsis;
}
.categroy_prd_cont p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
}
.categroy_prd_cont h5 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 0;
}
.like_cls {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgb(0 0 0 / 16%) !important;
  position: absolute;
  top: 10px;
  right: 10px;
}
.like_cls img {
  width: auto !important;
}
.like_cls {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 8px rgb(0 0 0 / 16%) !important;
  position: absolute;
  top: 10px;
  right: 10px;
}
.like_cls img {
  width: auto !important;
}
.artisan_review {
  display: flex;
  margin-bottom: 10px;
}
.most_active span {
  border: solid 1px #e1e1e1;
  padding: 7px 15px;
  border-radius: 50px;
  color: #707070;
  font-size: 13px;
  background: #fff;
}
.review_inner {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.review_inner .review_dtl {
  font-size: 12px;
  line-height: 1;
}
.review-ic {
  margin-right: 5px;
  margin-top: -1px;
}
.artisan_review + h4 {
  margin-bottom: 5px;
}
.internel_sec .offfer_banner {
  padding-bottom: 73%;
}
section.internel_sec {
  margin: 30px 0;
}
section.featured_artisan_sec .categroy_slider_img {
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 75%;
}
section.featured_artisan_sec .categroy_slider_img img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.like_cls img {
  width: auto !important;
  height: auto !important;
}
span.features_badge {
  position: absolute;
  width: 100%;
  top: 0;
}
.past_serv_inner {
  display: flex;
}

.past_serv_bg {
  background: #f8fafc;
  padding: 15px;
  border-radius: 10px;
  top: 2614px;
  left: 215px;
  width: 284px;
  height: 152px;
}

.past_serv_img img {
  border-radius: 10px;
  overflow: hidden;
}

.past_serv_img {
  margin-right: 10px;
  flex: 0 0 90px;
  max-width: 90px;
  position: relative;
}

.no_data {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.no_data_1 {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.no_data img {
  margin-right: 10px;
  flex: 0 0 90px;
  max-width: 20%;
  position: relative;
}

.no_data_1 img {
  margin-right: 10px;
  flex: 0 0 90px;
  max-width: 50%;
  position: relative;
}
.past_serv_tit {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.past_serv_cont h3 {
  color: #707070;
  opacity: 1;
  letter-spacing: 0px;
  font-size: 15px;
  margin: 5px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.past_serv_cont p {
  margin-bottom: 0;
  font-size: 14px;
}
.past_serv_cont {
  width: 100%;
}
.past_serv_cont {
  width: 100%;
  flex: 0 0 calc(100% - 100px);
  max-width: calc(100% - 100px);
}
.past_serv_tit .review_inner {
  margin-top: -2px;
}
span.tag_complete {
  background: #fff;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.tag {
  margin-bottom: 5px;
}
.btn_primary:hover {
  background: #a97e5e;
  color: #eaeaea;
  border-color: #a97e5e;
}
.past_serv_sec {
  margin: 30px 0;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background: #cf9e75;
  color: #fff;
  border-color: #cf9e75;
}
.main-btn {
  background: #cf9e75;
  border-radius: 7px;
  opacity: 1;
  border: none;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  padding: 10px 15px;
  height: 40px;
  white-space: nowrap;
}
.main-btn:hover {
  background-color: #c7946a;
  color: #fff !important;
  cursor: pointer;
}
.modal-container {
  border-radius: 50px;
}
.continue-btn {
  background-color: #cf9e75;
  border: none;
  border-radius: 10px;
  color: #fff;
  width: 100%;
  height: 40px;
  margin-top: 10px;
}

.modal-content {
  -webkit-border-radius: 25px !important;
  -moz-border-radius: 25px !important;
  border-radius: 25px !important;
  padding: 25px;
}
.close-icon {
  transform: rotate(315deg);
  border-radius: 50px;
  color: #cf9e75;
  font-size: larger;
}
input[type="file"] {
  display: none;
}

.header-stick {
  background-image: url(../img/banner.png);
  background-size: cover;
  background-position: center;
}

.category-main-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.category-block {
  border-radius: 15px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% / 6 - 30px);
  margin: 0 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.category-block:hover {
  cursor: pointer;
  box-shadow: 0px 0px 12px 7px rgb(209, 209, 209) !important;
}

.topTranding-img-block {
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  padding-bottom: 70%;
  margin-bottom: 10px;
  border: solid 1px #ffe8e8;
}
.topTranding-img-block img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: unset;
  width: 100% !important;
  height: unset;
}
.hr-header {
  border-top: 2px solid #ffffff;
}
.trending_category_bg {
  margin: 30px 0;
  background: #f8fafc;
  padding: 30px 0;
}
.modal-heading {
  text-align: left;
  font: normal normal normal 17px/24px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.modal-heading-2 {
  text-align: left;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.modal-heading-3 {
  text-align: left;
  font: normal normal 300 30px/48px Roboto;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}
.input-label {
  text-align: left;
  font: normal normal normal 15px/24px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.mob-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029 !important;
  border: 1px solid #d6d6d6;
  border-radius: 7px;
  opacity: 1;
  width: 100%;
  min-height: 40px;
  padding: 0 10px;
}
.form-group {
  margin-bottom: 20px;
}

.page-name {
  color: #eaeaea;
  font-size: large;
}
.artisans-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d6d6d6;
  border-radius: 15px;
  padding: 20px;
  max-width: 840px;
  margin: 0 auto;
}

input[type="radio"]:after {
  /* width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffffff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #CF9E75; */

  box-shadow: 0px 2px 4px #00000029;
  border: 1px solid #e2e2e2;
  border-radius: 13px;
  opacity: 1;
}

input[type="radio"]:checked:after {
  /* width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 5px solid #ffa500; */

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  border: 1px solid #cf9e75;
  border-radius: 13px;
  opacity: 1;
}

.orSectionLine {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  background: #ce9d78;
  border-radius: 50%;
  align-items: center;
  color: #fff;
  position: relative;
  z-index: 11;
  margin: 13px auto;
  font-size: 13px;
  font-weight: 300;
}
.gmap_box {
  position: absolute;
  left: 0;
  top: 0;
  /* width: 100%; */
  height: 50%;
}

.googleMap {
  width: 100%;
  height: 100%;
}

.mapcard {
  position: relative;
  height: 50vh;
  width: 100%;
  overflow: hidden;

  margin-bottom: 15px;
}

.modalStyle {
  width: 500px;
}

.otp-mob-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  min-height: 40px;
  margin: 0 10px;
  width: 100%;
  border: 1px solid #d6d6d6 !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%) !important;
  border-radius: 7px !important;
}
.profileModalStyle {
  width: 820px;
}
.profile_img {
  margin-left: 0;
  height: 105px;
  width: 105px;
  display: block;
}

.profile-label {
  margin-left: 15px;
}
.category-img {
  overflow: hidden;
  margin-bottom: 10px;
}
.category-img img {
  object-fit: cover;
}
img {
  max-width: 100%;
}
section.category_sec {
  margin: 40px 0;
}
.category-block p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
}
.modal-header .btn-close {
  padding: 0 !important;
  background-image: unset !important;
  height: 30px;
  width: 30px;
  opacity: 1;
  box-shadow: 0 3px 6px 0px #0000002e !important;
  border-radius: 50%;
}
.modal-header {
  padding-top: 10px;
  border-bottom: 0;
}
.react-tel-input .form-control {
  width: 100% !important;
}
.react-tel-input .flag-dropdown {
  border-radius: 7px 0 0 7px !important;
  background: transparent !important;
}
.react-tel-input .selected-flag {
  border-radius: 7px 0 0 7px !important;
}

.react-tel-input .flag-dropdown.open {
  border-radius: 107px 0 0 107px !important;
}
.search_form_cls input {
  min-height: 50px;
  padding-right: 50px;
}
.modal-header h5 {
  margin-bottom: 0;
}

.modal_cont h4 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.modal_cont h5 {
  color: #707070;
  font-size: 31px;
  font-weight: 300;
}

.modal_cont p {
  margin-bottom: 0;
  color: #707070;
  font-size: 15px;
  font-weight: 400;
}

.modal_cont {
  margin-bottom: 25px;
}

.custom_checkbox .form-check input.form-check-input {
  background-color: #fff;
  border-color: #e0e0e0;
  height: 20px;
  width: 20px;
  outline: 0 !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%) !important;
}
.remember_me_sec {
  margin-bottom: 20px;
}

.custom_checkbox .form-check {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.custom_checkbox .form-check label.form-check-label {
  line-height: 1;
  margin-bottom: 0;
  margin-left: 10px;
  margin-top: 4px;
  font-size: 13px;
}
.custom_checkbox .form-check-input:checked[type="checkbox"] {
  background-color: #ce9d78;
  border-color: #ce9d78;
}
.forgot_pw a {
  color: #0147ad;
  text-decoration: none;
  font-size: 16px;
}
.login-btn .btn {
  width: 100%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%) !important;
}
.forgot_pw {
  margin-top: 15px;
  margin-bottom: 10px;
}
.already_cls p {
  margin-bottom: 0;
  font-size: 16px;
}

.already_cls p a {
  color: #0147ad;
  margin-left: 5px;
  text-decoration: none;
}
.social-login {
  margin: 15px 0;
  display: flex;
}

.social-login a {
  margin-right: 15px;
}

.already_cls {
  margin-top: 25px;
}
.otp-mob-input input {
  width: 100% !important;
  border: 0;
  height: 100%;
  border-radius: 7px;
}
.btn-dark {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 7px;
  background: #000;
  color: #fff;
  border: 0;
  font-size: 16px;
  font-weight: 300;
  min-height: 30px;
  padding: 0 10px;
  min-width: 60px;
}
.pw-cls {
  position: relative;
}
.pw-cls input {
  padding-right: 70px;
}
.error_msg {
  font-size: 11px;
  width: 100%;
  color: #f00;
  display: block;
  line-height: 1.1;
  margin-top: 5px;
  width: 100%;
}

.checkout_bg_heavy {
  background: #f8fafc;
  padding: 25px;
  border-radius: 10px;
  height: 50%;
}

.checkout_error_msg {
  font-size: 15px;
  width: 100%;
  color: #f00;
  display: block;
  line-height: 1.1;
  margin-top: 8px;
  width: 100%;
  font-style: italic;
}

.form-group {
  position: relative;
}
section.artisans-sec {
  margin: 40px 0;
}
.artisans-box h4 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
}

.artisans-box p {
  margin-bottom: 10px;
  color: #707070;
  font-size: 16px;
  font-weight: 400;
}

.radio_cls {
  display: flex;
  align-items: center;
}

.custom_radio {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #707070;
  margin-right: 25px;
  line-height: 1.8;
  padding-left: 35px;
  font-weight: 300;
  display: block;
  position: relative;
  cursor: pointer;
  min-width: 150px;
}

/* Hide the browser's default radio button */
.custom_radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 0;
}

/* Create a custom radio button */
.custom_radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 4px #00000029 !important;
  border: 1px solid #d6d6d6;
}
/* When the radio button is checked, add a blue background */
.custom_radio input:checked ~ .checkmark {
  background-color: #f5f7fb;
  border: solid 2px #cf9e75;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom_radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom_radio .checkmark:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #cf9e75;
}

.your_location_wrap {
  margin-top: 20px;
  margin-bottom: 10px;
}

.your_location_wrap h5 {
  font-size: 15px;
  color: #000;
}
.addressDetails {
  margin-top: 2px;
}

label {
  display: block;
  font-size: 15px;
  color: #000000;
  margin-bottom: 5px;
}
.orSection {
  position: relative;
}

.orSection:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #cf9e75;
  left: 0;
  top: calc(50% - 0px);
}
.map-title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.map-title h6 {
  margin-left: auto;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 300;
  cursor: pointer;
}

.map-title h6 .locate_icon {
  margin-right: 10px;
}

.map-title h5 {
  font-size: 15px;
  margin-bottom: 0;
}
.map_card {
  height: 270px;
  margin-bottom: 15px;
}
.price-range-sec {
  margin-bottom: 65px;
}
.range_slider img {
  width: 100%;
}
.search_form_cls .form-group {
  margin-bottom: 0;
}
.input_group {
  width: 100%;
}
section.browse-artisan-sec {
  margin: 40px 0;
}
.browse-artisan-main {
  display: flex;
}
.browse-artisan-left {
  flex: 0 0 380px;
  max-width: 380px;
}

/* multi Slider */

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ddd;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #000;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #000000;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #cf9e75;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #cf9e75;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #000000;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #000000;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.range_slider {
  margin-top: 15px;
  position: relative;
}

.sidebar_bg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  opacity: 1;
}
.filter-top {
  padding: 10px 15px;
  border-bottom: solid 1px #f1f1f1;
  display: flex;
  align-items: center;
}
.filter-top h5 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}
.filter-top a {
  margin-left: auto;
  color: #0147ad;
  text-decoration: none;
  line-height: 1.2;
  font-size: 15px;
}
.sidebar_wrap select.form-select {
  max-width: 100%;
}

.sidebar_wrap {
  padding: 15px;
}
.sidebar_wrap h5 {
  font-size: 15px;
  font-weight: 500;
}
.service_main {
  margin-top: 30px;
}

.sidebar_cont {
  margin-bottom: 20px;
}

.rating_inner {
  display: flex;
  align-items: center;
}

.rating_inner span {
  margin-right: 15px;
}
.top-footer {
  background: #f8fafc;
  padding: 25px 0;
}

footer.footer .text-center {
  text-align: left !important;
}

.browse-artisan-right {
  width: calc(100% - 380px);
  flex: 0 0 auto;
  padding-left: 20px;
}
.rightside_artisan_top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.sort_by {
  margin-left: auto;
  min-width: 210px;
}
.rightside_artisan_top h5 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
}
.sort_by .form-group {
  margin-bottom: 0;
}
.artisan_bg {
  display: flex;
  background: #f8fafc;
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
}
.artisan_left_img {
  flex: 0 0 120px;
  position: relative;
  /* margin-right: 15px; */
}
.artisan_img {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  width: 140px;
  height: 140px;
  /* padding-bottom: 110%; */
}
.artisan_imgimg {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
}
.like_img {
  position: absolute;
  z-index: 11;
  background: #fff;
  height: 30px;
  width: 30px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
}
.artisan_right_cont {
  flex: 0 0 calc(100% - 140px);
  flex-shrink: unset;
  max-width: calc(100% - 140px);
  padding: 10px 0 0 10px;
}

.tag span {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 12px;
  padding: 3px 13px;
  font-size: 13px;
}

.artisan_title h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.artisan_title .tagLine {
  font-size: 12px;
  font-weight: 400;
}
.viewDetailbtn {
  font-size: 12px;
  padding: 10.5px 20px;
  min-height: unset;
  min-width: unset;
}
.artisan_title {
  margin: 5px 0;
}

.artisan_title h5 span {
  font-size: 12px;
  color: #707070;
}

.job_cont span {
  font-size: 14px;
  font-weight: 300;
  color: #000;
}

.review_cont span {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
}

.jobs_review {
  display: flex;
  padding-right: 15px;
  padding-top: 3px;
}

.review_cont {
  margin-left: auto;
}

.artisan_price_wrap {
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.artisan_price_left h5 {
  margin-bottom: 0;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
}

.artisan_view_btn {
  margin-left: auto;
}

.artisan_view_btn .btn_primary {
  border-radius: 12px 0 12px 0;
}
.jobs_review > div {
  line-height: 1;
}

.review_cont {
  display: flex;
  align-items: center;
}
.jobs_review > div {
  line-height: 1;
}

.review_cont {
  display: flex;
  align-items: center;
}
.artisan_img img {
  object-fit: cover;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 100%;
  width: 100% !important;
}

.google-login iframe {
  opacity: 0;
  width: 100% !important;
}
.google-login {
  position: relative;
  height: 40px;
  margin: 15px 0 0;
}

.google-login a {
  position: absolute;
  width: 100%;
  top: 0;
}

.google-login a + div {
  position: absolute;
}
.profile-section {
  background: #f8fafc;
  padding: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.profile_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.profile-section label {
  margin-bottom: 0;
}
.otp-mob-input:first-child {
  margin-left: 0;
}
.footer_title span {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  line-height: 1.2;
}
.footer-widget {
  padding-left: 90px;
}
.top-footer [class*="col-"]:first-child .footer-widget {
  padding-left: 0;
}
.footer_title {
  margin-bottom: 15px;
}
.footer-widget p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.6;
}
.footer-widget .list-unstyled {
  margin-bottom: 0;
}
.footer-widget .list-unstyled li {
  padding-bottom: 7px;
}
.footer-widget .list-unstyled li a {
  font-size: 15px;
  display: flex;
  align-items: center;
}
.footer-widget .list-unstyled li a .footer_left_icon {
  line-height: 1;
  margin-right: 10px;
  margin-top: -2px;
}
.list-unstyled.footer_help li {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.list-unstyled.footer_help li .black_bg {
  height: 24px;
  width: 24px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 0 0 24px;
  border-radius: 5px;
}

.list-unstyled.footer_help li .black_bg i {
  color: #fff;
  font-size: 12px;
}
.footer-widget .list-unstyled li:last-child {
  padding-bottom: 0;
}
.footer {
  margin-top: 35px;
}
.bottom-footer {
  padding: 10px 0;
}

.bottom-footer p {
  margin-bottom: 0;
  font-size: 15px;
}

.footer_social ul {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.footer_social ul li {
  padding: 0 10px;
}
.footer_social ul li:first-child {
  padding-left: 0;
}
.footer_social {
  text-align: center;
  display: flex;
  justify-content: center;
}
.download_app {
  display: flex;
  justify-content: end;
}
.download_app a {
  margin-left: 15px;
}

.bottom-footer .row {
  align-items: center;
}
.category_col {
  flex: 0 0 calc(100% / 6);
  padding: 0 15px;
}
.category-img {
  text-align: center;
  background: #f8fafc;
  border-radius: 15px;
  padding: 12px;
  margin-bottom: 10px;
}
.category-img img {
  width: 100%;
  border-radius: 10px;
}
.category-inner p {
  margin-bottom: 0;
  font-size: 15px;
  color: #000000;
}
.category-inner {
  text-align: center;
  margin-bottom: 15px;
}
.trending_cate {
  text-align: center;
}

.trending_cate span {
  color: #000;
  font-size: 15px;
  display: block;
}
.time_list_wrap {
  flex: 0 0 35%;
  max-width: 35%;
  padding-left: 20px;
}

.time_map_left {
  flex: 0 0 65%;
  max-width: 65%;
}

.selecttime_main {
  display: flex;
}
.select-time-sec {
  margin: 35px 0;
}
.time_bg_wrap {
  background: #f8fafc;
  padding: 20px;
  border-radius: 15px;
  max-height: 660px;
  overflow-x: auto;
}
.time_bg_wrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.time_bg_wrap::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.time_bg_wrap::-webkit-scrollbar-thumb {
  background-color: #ce9d78;
}

.time_bg_wrap .artisan_bg {
  background: #fff;
  border: solid 2px #fff;
}
.artisan_bg.select {
  border: solid 2px #ce9d78;
}
.time_bg_wrap .artisan_bg:last-child {
  margin-bottom: 0;
}
.artisan_dtl_inner_cont {
  /* text-align: center; */
  border: dashed 1px #0147ad;
  padding: 5px 20px;
  border-radius: 50px;
  margin-top: 5px;
  background: #f8fafc;
  display: inline-block;
  align-items: center;
  justify-content: center;
}
.artisan_dtl_inner_cont p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}

.artisan_right_cont.artisan-dtl-cont {
  padding: 15px;
}

.artisan_right_cont.artisan-dtl-cont .artisan_title {
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  align-items: center;
}

.artisan_dtl_right {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.artisan_right_cont.artisan-dtl-cont .artisan_title span.like_img {
  position: unset;
}
.artisan_dtl_right .tag {
  margin-bottom: 0;
  margin-right: 10px;
  white-space: nowrap;
}
.artisan_right_cont.artisan-dtl-cont .jobs_review {
  padding-top: 0;
}

section.artisan-detail-sec {
  margin: 35px 0;
}
.christophe_profile {
  display: flex;
  align-items: center;
}
.associated_bg {
  background: #f8fafc;
  padding: 15px;
  border-radius: 15px;
}
/* .christophe_img {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  flex: 0 0 115px;
  max-width: 115px;
} */

.christophe_img {
  background: #fff;
  padding: 0px;
  border-radius: 8px;
  flex: 0 0 70px;
  max-width: 70px;
}

.Christophe_user_name {
  margin-left: 20px;
  white-space: normal;
  word-break: break-all;
  font-size: 16px;
  color: #000000;
}
.associted_inner_cont h5 {
  font-size: 15px;
  font-weight: 400;
}

section.manage-address-sec {
  margin: 35px 0;
}

.manage_address_inner {
  padding: 0;
  width: 100%;
}
.single_address {
  background: #fff;
  position: relative;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
}

.single_address label.custom_radio {
  height: 24px;
  padding: 0;
  margin: 0;
}

.single_address label.custom_radio span.checkmark {
  right: 0;
  left: auto;
}

.select_option {
  position: relative;
  display: flex;
  margin-top: 10px;
}

.select-address {
  margin-left: auto;
}

.edit_delete_cont {
  flex: 0 0 120px;
}

.img_icon {
  cursor: pointer;
}

span.delete_cls {
  margin-left: 15px;
}

.manage_address_inner h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.single_address h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.single_address p {
  font-size: 18px;
  font-weight: 300;
  color: #707070;
  margin-bottom: 0;
}
.navbar .navbar-nav .dropdown .profileImg {
  max-width: 40px;
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 0;
  margin-right: 0;
  margin-left: 0;
}
.navbar .navbar-nav .dropdown {
  background: transparent;
  border-radius: 10px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}
.navbar-expand-lg .navbar-nav .dropdown button {
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0;
  margin-left: 0;
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 0 0 40px;
  white-space: nowrap;
  display: block;
  text-align: left;
  width: auto;
}
.navbar-expand-lg .navbar-nav .dropdown button::after {
  display: none;
}
.artisan_about_cont h4 {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 0px;
}

.artisan_about_cont p {
  font-size: 15px;
  line-height: 1.8;
  font-weight: 400;
}
.service-price {
  margin-top: 15px;
  background: #f8fafc;
  border-radius: 10px;
  display: flex;
  padding: 15px 0;
  border: solid 1px #cf9e75;
}

.service_price_left {
  flex: 0 0 50%;
  padding: 0 15px;
}

.service_price_right {
  flex: 0 0 50%;
  padding: 0 15px;
  text-align: right;
  border-left: solid 1px #707070;
}
.service-price span {
  font-size: 15px;
}

span.price_highlight {
  font-weight: 500;
}
.service-price span {
  font-size: 15px;
}
.price_highlight {
  font-weight: 500;
}
.selected_service {
  display: flex;
  margin-top: 15px;
}
.selected_service .artisan_dtl_inner_cont {
  margin-top: 0;
}
.selected_service .continue_btn {
  margin-left: auto;
}
.service-detail {
  display: flex;
}

.service_slider {
  flex: 0 0 65%;
  max-width: 65%;
  padding-right: 25px;
}

.serv_dtl_cont {
  flex: 0 0 35%;
  max-width: 35%;
}
.serv_slider_img img {
  width: 100%;
}
.service-detail-sec {
  margin: 35px 0;
}

.serv-dtl-title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.serv_type {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.serv-like .like {
  margin-left: 10px;
  background: #fff;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
}

span.soft {
  background: #ff709b;
  padding: 5px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  height: 21px;
  color: #fff;
  font-size: 12px;
}

span.heavy {
  background: #48afde;
  padding: 5px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  height: 21px;
  color: #fff;
  font-size: 12px;
}

span.offline {
  background: #f66710;
  padding: 5px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  height: 21px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
}

.serv-dtl-title h3 {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 300;
}
.serv_dtl_cont p {
  color: #707070;
  font-size: 15px;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.serv_dtl_cont h6 {
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
}
.serv_dtl_cont ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.serv_dtl_cont ul li {
  display: flex;
  margin-bottom: 5px;
}
span.serv_name {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 300;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.search_book_artisan {
  display: flex;
  margin-top: 15px;
}
.search_book_artisan button {
  margin-right: 15px;
}
.serv_slider_img {
  padding-bottom: 55%;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.serv_slider_img img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.fetured_artisan_sec .categroy_slider_img {
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 70%;
}

.fetured_artisan_sec .categroy_slider_img img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  height: 100%;
}
.artisan_type {
  margin: 8px 0;
}

.artisan_type span {
  background: #fff;
  border: solid 1px #eeeeee;
  padding: 4px 10px;
  border-radius: 5px;
}
.fetured_artisan_sec {
  margin-top: 20px;
}
.artisan_price span {
  font-size: 16px;
  font-weight: 500;
}

.artisan_price {
  margin: 5px 0;
}
.watch_demo_sec {
  margin: 25px 0;
}
.video_main_sec {
  display: flex;
}
.viedo_cont {
  flex: 0 0 460px;
  max-width: 460px;
  padding-left: 25px;
}
.video-inner {
  flex: 0 0 calc(100% - 460px);
}
.video {
  position: relative;
  padding-bottom: 45%;
  overflow: hidden;
  border-radius: 10px;
}
.video video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.video iframe {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.video img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.video_play_icon {
  position: absolute;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_play_icon img {
  position: unset;
  max-width: 40px;
  height: unset;
  transform: unset;
}
.video_cont_inner {
  background: #f8fafc;
  /* height: 100%; */
  border-radius: 10px;
  padding: 20px;
}
.video_cont_inner h5 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.video_cont_inner ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.video_cont_inner ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  font-size: 15px;
  color: #707070;
}
.video_cont_inner ul li:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #ce9d78;
  border-radius: 50%;
  left: 0;
  top: 8px;
}
.video_cont_inner ul li:last-child {
  margin-bottom: 0;
}
.slect_category_check .custom_checkbox {
  display: block;
  position: relative;
  margin-bottom: 0;
  padding-left: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slect_category_check .custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.slect_category_check .checkmark {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  z-index: 11;
  display: block;
  border: solid 2px #d39e79;
  border-radius: 50%;
  padding: 0 !important;
  background: #fff;
  box-shadow: none !important;
}
.slect_category_check .checkmark img {
  display: none !important;
}
.slect_category_check input:checked ~ .checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slect_category_check input:checked ~ .checkmark img {
  height: unset !important;
  width: auto;
  display: block !important;
}
.single_serv span img {
  max-height: 55px;
  object-fit: cover;
  width: 100%;
}
.category-img img {
  height: 75px;
  object-fit: cover;
}
.checkout-inner {
  display: flex;
}

.checkout_left {
  flex: 0 0 calc(100% - 500px);
}

.checkout_bg {
  background: #f8fafc;
  padding: 20px;
  border-radius: 10px;
}

.checkout_bg .artisan_bg {
  background: transparent;
  padding: 0;
  margin: 10px 0;
  align-items: center;
}

.checkout_cont h3 {
  font-size: 20px;
  font-weight: 300;
}

.checkout_bg .artisan_left_img {
  max-width: 140px;
  flex: 0 0 auto;
}

.checkout_bg .artisan_left_img .artisan_img {
  padding-bottom: 100%;
}

.checkout_bg .artisan_right_cont.artisan-dtl-cont {
  flex: 0 0 calc(100% - 140px);
  /* padding: 0; */
}

.checkout_bg .artisan_right_cont.artisan-dtl-cont .jobs_review {
  padding: 0;
}

.checkout_selected_serv {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.checkout_selected_serv h5 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}
.signle_select_serv {
  display: flex;
  margin-bottom: 10px;
}
.signle_select_serv:last-child {
  margin-bottom: 0;
}
.serv_no span {
  font-size: 18px;
  font-weight: 300;
  line-height: 1;
}
.serv_no {
  flex: 0 0 25px;
}
.serv_desc h4 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
}
.serv_edit_option {
  margin-left: auto;
}
.black_btn {
  background: #000;
  border-color: #000;
  font-weight: 300;
  font-size: 16px;
  padding: 10px 12px;
  line-height: 1;
  color: #fff;
}

.serv_desc p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}
.customer_select_address {
  margin-top: 15px;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}
.customer_select_address h3 {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}
.customer_select_address p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
}
.change_address {
  display: flex;
  margin-top: 10px;
}
.distance_map {
  margin-left: auto;
  display: flex;
  font-weight: 300;
  align-items: center;
}

span.map_icon {
  margin-left: 10px;
}

.distance_map span {
  font-size: 14px;
}
.price-specification {
  margin-top: 30px;
}
.price-specification h3 {
  font-size: 17px;
  font-weight: 500;
}
.price-specification ul {
  padding-left: 0;
  margin-bottom: 20px;
  list-style: none;
}
.price-specification li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.checkout-price-left {
  font-size: 15px;
}
.checkout-price-right {
  margin-left: auto;
  font-weight: 500;
  font-size: 16px;
}
.checkout-coupon {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  margin-top: 60px;
}
.checkout-bg {
  background: #f8fafc;
  padding: 25px;
  border-radius: 10px;
  height: 100%;
}
.checkout-right {
  flex: 0 0 500px;
  padding-left: 30px;
}
section.checkout-main {
  margin: 30px 0;
}
span.apply_coupon {
  margin-left: 10px;
  font-size: 15px;
}
.card_detail {
  /* font-size: 18px;
  font-weight: 500; */
  margin-top: 50px;
}
.checout_form label {
  font-weight: 500;
  margin-bottom: 0px;
}
.footer-widget .list-unstyled li span:last-child {
  margin-left: 5px !important;
}
.thankyou_inner {
  max-width: 500px;
  background: #f8fafc;
  margin: 30px auto;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
}

.thankyou-icon {
  margin-bottom: 20px;
}

.thankyou-cont h3 {
  font-size: 53px;
  font-weight: 300;
  margin-bottom: 15px;
}

.thankyou-cont p {
  font-size: 17px;
  max-width: 80%;
  margin: 0 auto;
}

p.booking_id {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #707070;
}

.thankyou-btn {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 15px 0;
}

.order-btn {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.order-btn button {
  font-size: 16px;
  color: #000000;
  border: solid 2px #000000;
  background: #fff;
  padding: 0 15px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-btn a {
  font-size: 16px;
  color: #000000;
  border: solid 2px #000000;
  background: #fff;
  padding: 0 15px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
span.invoice-icon {
  margin-right: 9px;
}

.order-btn button:hover {
  background: #ce9d78;
  border-color: #ce9d78;
}

.order-btn a:hover {
  background: #ce9d78;
  border-color: #ce9d78;
}
.slect_category_check .custom_checkbox {
  display: block;
  position: relative;
  margin-bottom: 0;
  padding-left: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.slect_category_check .custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.slect_category_check input:checked ~ .checkmark {
  display: block;
}

.subcategory_select {
  display: block;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.subcategory_select input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.subcategory_select .checkmark {
  height: 100%;
  width: 100%;
  display: block;
  background-color: #f8fafc;
  border: solid 1px #f8fafc;
  text-align: center;
  padding: 12px;
  border-radius: 15px;
}
.subcategory_select input:checked ~ .checkmark {
  border-color: #cf9e75;
}
.subcateogry-name h5 {
  font-size: 15px;
  margin-bottom: 0;
  text-align: center;
  margin-top: 10px;
}
.subcategory-inner {
  display: flex;
  align-items: center;
}

.subcategory-left {
  flex: 0 0 55%;
  max-width: 55%;
  padding-right: 20px;
}
.subcategory-right {
  flex: 0 0 45%;
}

.subcat-main-img img {
  border: solid 1px #e9e9e9;
  border-radius: 10px;
  background: #e9e9e9;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
  height: 100%;
}
.single-subcategory {
  position: relative;
  flex: 0 0 33.33%;
  margin-bottom: 15px;
  padding: 0 15px;
}

.subcategory-inner-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.single-subcategory .subcategory_select {
  margin-bottom: 0;
}

.subcate-icon {
  width: 100%;
  height: 75px;
  overflow: hidden;
  border-radius: 10px;
}
span.features_badge img {
  width: auto !important;
  height: auto !important;
  position: unset !important;
  transform: unset !important;
}
.subcate-icon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.subcategory_sec {
  margin-bottom: 30px;
}

.artisan_category_list .categroy_slider_inner {
  margin-bottom: 30px;
  position: relative;
  border: solid 1px #f8fafc;
}

.heavy_serv_inner {
  display: flex;
  margin: 0 -15px;
  margin-bottom: 20px;
}
.heavy-serev-left {
  padding: 0 15px;
  flex: 0 0 50%;
}
.heavy-serv-right {
  flex: 0 0 50%;
  padding: 0 15px;
}
.heavy-serv-img {
  position: relative;
  padding-bottom: 70%;
  overflow: hidden;
  border-radius: 15px;
}
.heavy-serv-img img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
section.heavy-service-sec {
  margin: 35px 0;
}
.stepbar {
  background: #f5f5f5;
  height: 8px;
  border-radius: 10px;
  display: flex;
}
.step_topbar {
  margin-bottom: 30px;
}
span.fillbar {
  flex: 0 0 calc(100% / 7);
  background: #ce9d78;
  border-radius: 10px;
}
.step_topbar h5 {
  font-size: 15px;
  font-weight: 300;
  color: #707070;
  margin-bottom: 10px;
}
.black_btn:hover {
  background: transparent;
  color: #000;
  border-color: #000;
}
.coupon_inner {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #d6d6d6;
  border-radius: 15px;
  max-width: 740px;
  margin: 0 auto;
}
section.coupon-main {
  margin: 35px 0;
}
.apply-code-inner {
  position: relative;
}
.apply-code-inner .black_btn {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 6px 10px;
}
.apply-code-inner .form-control {
  padding-right: 75px;
}
.coupon_code {
  border-bottom: solid 1px #e3e3e3;
  padding: 20px;
}
.coupon_code .form-group {
  margin-bottom: 0;
}
.available_code .title_cls {
  padding: 20px 20px 0;
}
.available_code_list {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.coupocode-inner {
  border-bottom: solid 1px #e3e3e3;
  /* padding: 20px; */
}
.coupon-code-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.coupocode-inner:first-child {
  padding-top: 0;
}
.coupocode-inner:last-child {
  border-bottom: 0;
}
.coupon-code-btn span {
  color: #cf9e75;
  font-size: 20px;
  font-weight: 500;
  border: dashed 1px #cf9e75;
  padding: 9px 30px;
  display: block;
  border-radius: 10px;
}
.coupocode-inner .code_off {
  font-size: 17px;
  margin-bottom: 0;
  font-weight: 400;
  margin: 0;
}
.coupocode-inner p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
  margin: 8px 0;
}
.coupocode-inner a {
  color: #0147ad;
  text-decoration: none;
  font-size: 14px;
}
input.postal-code.form-control {
  width: 120px;
  max-width: 120px;
  border-radius: 7px 0 0 7px !important;
  border-right: 0 !important;
  position: relative;
  z-index: 11;
  height: 10px;
}

input.postal-code.form-control + input.form-control {
  border-radius: 0 7px 7px 0 !important;
  border-left: 0 !important;
}
.multi-select .property-bx {
  background: #f8fafc;
  padding: 30px 10px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 15px;
  height: calc(100% - 15px);
  border: solid 1px #f8fafc;
}
.multi-select .custom_checkbox {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0 10px;
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.multi-select .custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.multi-select .custom_checkbox input:checked ~ .checkmark + .property-bx {
  border: solid 1px #f79337;
}
.multi-select {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.step-find {
  display: flex;
  justify-content: space-between;
}
.step-find {
  display: flex;
  justify-content: space-between;
}
.pr-name h5 {
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
}
.heavy-radio-serv label.custom_radio {
  margin-bottom: 12px;
  margin-top: 5px;
}

a {
  text-decoration: none !important;
}
.autocomplete-dropdown-container > div {
  background: transparent !important;
  padding: 10px;
  border-bottom: solid 1px #f3d2b9;
  font-size: 14px;
}
.autocomplete-dropdown-container {
  box-shadow: 0 0 20px rgb(0 0 0 / 33%) !important;
  border-radius: 10px;
  position: absolute;
  top: 70px;
  z-index: 1111111;
  width: 100%;
  background: #fff !important;
}
.autocomplete-dropdown-container > div:last-child {
  border-bottom: 0 !important;
}
.btn a {
  color: #fff;
}
.my-account-inner {
  display: flex;
}
.account-sidebar {
  flex: 0 0 300px;
  max-width: 300px;
  margin-right: 20px;
}
.m_sidebar {
  background: #fff;
  border: solid 1px #e3e3e3;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%) !important;
  padding: 20px;
  border-radius: 10px;
}
.order-main {
  margin: 35px 0;
}
.m_sidebar ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.m_sidebar ul li {
  margin-bottom: 20px;
}
.m_sidebar ul li a {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 15px;
}
.m-icon {
  margin-right: 20px;
}
.m-icon img {
  filter: brightness(0);
}
.m_sidebar ul li:last-child {
  margin-bottom: 0;
}
.m_sidebar ul li a.active {
  color: #0a48aa;
}
.m_sidebar ul li a.active .m-icon img {
  filter: unset;
}
.white_bg .nav-item a {
  background: transparent !important;
  color: #707070;
  font-size: 18px;
  font-weight: 300;
  padding: 0 15px 5px !important;
}

.white_bg .nav-item a.active {
  color: #000;
  font-weight: 400;
  padding: 0;
  border-bottom: solid 1px #707070;
  border-radius: 0;
}

.white_bg.nav {
  margin-bottom: 10px;
}
.account-sidebar-right {
  max-width: calc(100% - 330px);
  flex: 0 0 calc(100% - 450px);
}
.m_sidebar_bg {
  padding: 15px;
  background: #f8fafc;
  width: 100%;
  border-radius: 10px;
}

.white_bg.nav {
  margin-bottom: 10px;
}

.account-sidebar-right {
  max-width: calc(100% - 330px);
  flex: 0 0 calc(100% - 450px);
}

.order-inner {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
}
.order-inner:last-child {
  margin-bottom: 0;
}
.order-top {
  display: flex;
  align-items: center;
}
.order-status {
  margin-left: auto;
  display: flex;
  align-items: center;
}

span.order-serv-type {
  margin-right: 10px;
  background: #ff709b;
  padding: 1px 10px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}
.order-type-status {
  padding: 2px 10px;
  background: #ffffff;
  border-radius: 20px;
  font-size: 13px;
  border: solid 1px #fff;
  min-height: 25px;
  display: flex;
  align-items: center;
  min-width: 100px;
  justify-content: center;
}
.complete {
  border-color: #38c16f;
  color: #38c16f;
}
.order-id h5 {
  margin-bottom: 0;
  font-size: 17px;
}
.order-date {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
}
.order-desc {
  margin-bottom: 5px;
}
.order-serv-name {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.order-price {
  margin-left: auto;
}
.order-serv-name h4 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 0;
}
.order-price span {
  font-size: 18px;
  font-weight: 400;
}
.order_btn {
  display: flex;
  gap: 15px;
}
.btn_black {
  min-width: 120px;
  background: #000;
  border-color: #000;
}
.btn_black:hover {
  background: #fff;
  border-color: #000;
  color: #000;
}
.progress {
  border-color: #eeac00;
  color: #eeac00;
}

span.order-serv-heavy {
  margin-right: 10px;
  background: #48afde;
  padding: 1px 10px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}

span.order-serv-off {
  margin-right: 10px;
  background: #f66710;
  padding: 1px 10px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}

.cancel {
  border-color: #fe0418;
  color: #fe0418;
}

.login-btn .btn a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.cross_img {
  position: absolute;
  z-index: 11;
  background: #fff;
  height: 25px;
  width: 25px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
}

.tab-pane .artisan_list_wrap .artisan_bg {
  background: #fff;
}

.artisans_radioBtn {
  margin-bottom: 15px;
}
.favorite-serv .categroy_slider_inner {
  background: #fff;
  margin-bottom: 15px;
}
span.cross_img img {
  position: unset;
  transform: unset;
}
.noti_space {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px;
}
.noti_space p {
  margin-bottom: 5px;
  font-size: 17px;
}

.notii_space {
  background: #f6f6f6;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px;
}
.notii_space p {
  margin-bottom: 5px;
  font-size: 17px;
}
.noti_time span {
  font-size: 13px;
  font-weight: 300;
}
.notification-dropdown button {
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
}
.notification-dropdown button::after {
  display: none;
}
.notification-dropdown .dropdown-menu {
  inset: unset !important;
  left: auto !important;
  right: 0 !important;
  top: 0px !important;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);
  border: 0;
  position: relative;
}
.verify_btn {
  position: relative;
}
.verify_btn .btn_black {
  min-width: unset;
  position: absolute;
  top: 5px;
  padding: 3px 10px;
  font-size: 15px;
  font-weight: 300;
  right: 5px;
}

.m-account-cls {
  display: flex;
}

.profile_update {
  flex: 0 0 35%;
  max-width: 35%;
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  margin-right: 15px;
}
.profile_update_bg {
  text-align: center;
}
.profile-img {
  height: 100px;
  width: 100px;
}
.profile-img img {
  object-fit: cover;
  object-position: top center;
}
.profile_cont h3 {
  font-size: 17px;
  font-weight: 400;
  margin: 15px 0;
}
.profile-upload-btn {
  width: auto !important;
}
.profile-upload-btn .btn.btn_primary {
  width: auto !important;
  max-width: 120px;
  margin: 0 auto;
}
.became-artisan a {
  justify-content: space-between;
  min-height: 50px;
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid #d6d6d6 !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%) !important;
  border-radius: 7px !important;
  padding: 0 15px;
  color: #000;
  font-size: 18px;
}

.m-account-extrnal {
  margin-top: 25px;
}
.link-box {
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%) !important;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.extrnal_links {
  margin-top: 20px;
}
.link-box h5 {
  margin-top: 15px;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}
.link-ic {
  height: 42px;
}

.rating_inner > button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.off {
  color: #ccc;
  transition-duration: 200ms;
}
.rating_inner button.on {
  transition-duration: 300ms;
  -webkit-transform: scale(1.2);
  color: #eeac01;
}

.rating_inner button {
  font-size: 30px;
}
.rating_inner span {
  margin-right: 0px;
  line-height: 1;
  display: block;
}
.order-dtl-top {
  display: flex;
  margin-bottom: 10px;
}

.order-top-left h5 {
  font-size: 22px;
  margin-bottom: 2px;
  font-weight: 300;
}

.order-top-left p {
  margin-bottom: 0;
  font-size: 15px;
  color: #707070;
}

.order-top-right h5 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
}

.order-top-right {
  margin-left: auto;
}

.artisan-order {
  display: flex;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
}

.order_track_btn {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-art-img {
  flex: 0 0 90px;
  max-width: 90px;
  margin-right: 10px;
}

.order-art-img img {
  border-radius: 10px;
}

.order-artisan-cont h5 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
}

.order-artisan-cont p {
  margin-bottom: 0px;
  font-size: 12px;
}
.order-price-specification {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
}

.order-price-specification h5 {
  font-size: 17px;
  font-weight: 500;
}

.order-price-specification ul {
  max-width: 440px;
}
.order-price-specification .price-specification {
  margin-top: 0;
}
.other-service h5 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
}
.other-service {
  background: #fff;
  margin: 15px 0;
  padding: 15px;
  border-radius: 10px;
}
.other-service ul {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  list-style: none;
}
.other-service ul li {
  background: #f9f9f9;
  border: 1px solid #e2e2e2;
  margin-right: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
  min-width: 100px;
  text-align: center;
}
.checkout-coupon a {
  color: #000;
  display: flex;
}
.order-detail .categroy_slider_inner {
  background: #fff;
}
.wallet_left-inner {
  display: flex;
}

.wallet-top {
  display: flex;
  width: 100%;
  height: auto;
  align-items: self-start;
}

.wallet_coupon_option {
  display: flex;
  margin-left: auto;
  background: #fff;
  padding: 15px;
  min-width: 300px;
  border-radius: 10px;
}

span.wallet-icon {
  margin-right: 15px;
}

.wallet-amount h4 {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 5px;
}

.wallet-amount h3 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
  margin-bottom: 5px;
}

.wallet-amount p {
  margin-bottom: 0;
  font-size: 14px;
}

.copuon_cont h5 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.coupon-icon {
  flex: 0 0 45px;
  margin-right: 0;
  max-width: 45px;
  height: auto;
}

.copuon_cont {
  width: 100%;
  margin-left: 15px;
}

.copuon_cont p {
  margin-bottom: 0;
  font-size: 14px;
}

.wallet_left {
  height: 100%;
}
.wallet-history {
  background: #fff;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}
.wallet-history h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}
.histroy-title {
  display: flex;
  justify-content: space-between;
}
.histroy-title h5 {
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0;
}
.single-history .histroy-title h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 500;
}
.single-history h6 {
  font-size: 14px;
  margin-bottom: 3px;
  margin-top: 3px;
}
.single-history p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
}
.single-history {
  padding: 15px 0;
  border-bottom: solid 1px #e2e2e2;
}
.wallet_history_listing .single-history:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.mission-img img {
  width: 100%;
}
.our-mission .row {
  align-items: center;
}
section.aboutus-main {
  margin: 35px 0;
}
.aboutinner-page h5 {
  font-size: 20px;
  margin-bottom: 10px;
}
.aboutinner-page p {
  font-size: 15px;
  color: #707070;
}
.how-we-work-sec .accordion-item {
  margin-bottom: 20px;
  background: #f8fafc;
  border: 0;
  border-radius: 10px;
}
.how-we-work-sec .accordion-item button.accordion-button {
  background: transparent;
  outline: 0 !important;
  box-shadow: none !important;
  color: #000;
  font-size: 17px;
  font-weight: 500;
}
.how-we-work-sec {
  margin-top: 20px;
}
.how-we-work-sec h5 {
  font-size: 30px;
  font-weight: 300;
}
.how-we-work-sec .accordion-body {
  padding-top: 0;
}
ul.navbar-nav li a:hover {
  color: #ce9d78 !important;
}

.time-label {
  display: block;
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}
.time-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.time-label .checkmark {
  height: 40px;
  width: 100%;
  border: solid 2px #0000;
  background: #f8fafc;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
}
.time-label input:checked ~ span .checkmark {
  border-color: #ce9d78;
}
.time-slot {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  margin-top: -10px;
}
.time-slot label.time-label {
  flex: 0 0 20%;
  padding: 0 5px;
}
.time-select-slot h5 {
  display: block;
  font-size: 15px;
  color: #000000;
  margin-bottom: 5px;
  font-weight: 400;
}
.time_map_left h4 {
  font-size: 18px;
}
.footer-widget .list-unstyled li a .footer_left_icon {
  margin-left: 0 !important;
}
.past_serv_tit h5 {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: bold;
}

.form-label {
  margin-bottom: 5px;
}
.otp_timer p {
  font-size: 14px;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
}
.login-btn.chekout-btn button.btn.btn_primary {
  box-shadow: none !important;
  padding: 0 !important;
  background: #cf9e75 !important;
  font-size: 15px;
  color: #fff;
  min-height: 40px;
  line-height: 1;
  text-transform: uppercase;
  min-width: 120px;
}
.login-btn.chekout-btn button.btn.btn_primary span {
  background: transparent !important;
  box-shadow: none !important;
  height: unset !important;
  line-height: unset !important;
  font-size: unset !important;
  text-shadow: none !important;
  font-weight: unset !important;
}

.login-btn.chekout-btn button.btn.btn_primary:hover {
  background: #a97e5e !important;
  color: #eaeaea !important;
  border-color: #a97e5e !important;
}
.mission-cont h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}

.mission-cont p {
  margin-bottom: 0;
}
.artisan_right_cont.artisan-dtl-cont h5 {
  white-space: unset;
}
.slider__right-value {
  right: 0px;
}
.sidebar_cont .range_slider {
  height: 35px;
}
.artisan_list_wrap [class*="col-"]:last-child .artisan_bg {
  margin-bottom: 0;
}
.m_sidebar_bg .title_cls {
  align-items: center;
}
.file_input input[type="file"] {
  display: block;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.file_input {
  position: relative;
}

.modal-title {
  text-align: left;
  font: normal normal 300 30px/48px Roboto;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

/* User Profile Dropdown */

a {
  color: #000;
  text-decoration: none;
}
a :hover {
  color: #000;
  text-decoration: none;
}

/* User Profile Dropdown */

.add_address {
  display: flex !important;
  align-items: start !important;
  justify-content: space-between !important;
}

.react-datepicker-popper {
  z-index: 11111 !important;
}

.time-label input:checked ~ .checkmark {
  border: solid 2px #d39e79;
}

section.artisans-sec .manage_address_listing .single_address {
  background: #f8fafc;
}
section.artisans-sec .select-address {
  position: absolute;
  right: 15px;
  top: 15px;
}
section.artisans-sec .manage_address_listing .single_address p {
  padding-right: 40px;
}
section.artisans-sec .manage_address_listing .single_address h5 {
  padding-right: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.form-group.pw-show button.btn-dark {
  top: 32px;
}

.s-box input {
  width: 100%;
  min-height: 45px;
  border-radius: 5px;
  border: 0;
  box-shadow: none !important;
  outline: 0 !important;
  padding: 0 10px;
}
div#cc-number iframe {
  border-radius: 7px;
  height: 45px !important;
  padding: 0 10px !important;
}

.s-box .col-md-7.order-md-1 {
  flex: 0 0 100%;
  max-width: 100%;
}

.checkout-coupon span.cross_img {
  height: 25px;
  width: 25px;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 50%;
  display: flex;
  position: absolute;
  z-index: 111;
  padding: 0;
  right: -5px;
  top: -10px;
}
.checkout-coupon .coupon-code-btn {
  position: relative;
}

.order-top-left {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-top-para p {
  font-weight: bold;
}

.no-content-cls {
  min-height: 105px;
}
.subcat-main-img {
  padding-bottom: 65%;
  position: relative;
}
span.check_ic {
  flex: 0 0 20px;
}
.other-service ul.other-serv-inner li.cursor-pointer.active {
  background: #fff;
  box-shadow: 2px 4px 8px rgb(0 0 0 / 15%);
  border: 0;
}

.add_address button.btn_primary.mb-4.btn.btn-primary {
  margin-bottom: 0 !important;
}
.dropdwon_inner.dropdown button {
  min-width: 40px !important;
  min-height: 40px !important;
  width: 40px;
  height: 40px;
  padding: 0 !important;
  border-radius: 50%;
  overflow: hidden;
}

.time_remove {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.s-box {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px 10px;
  border: 0;
  box-shadow: 0 0 5px rgb(0 0 0 / 16%);
}
.s-box input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a8b7c3;
  opacity: 1; /* Firefox */
  font-size: 14px;
}

.s-box input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a8b7c3;
  font-size: 14px;
}

.s-box input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a8b7c3;
  font-size: 14px;
}

.totalServices {
  font-size: 14px;
  background-color: #ff709b;
  width: 20px;
  height: 20px;
}
.Totalservices {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.Totalservices .serviceItem {
  font-size: 15px;
  line-height: normal;
}

section.search-sec {
  box-shadow: 0px 0px 4px rgb(0 0 0 / 16%);
  padding: 12px 0;
}

section.search-sec .search_cls {
  margin-left: auto !important;
  margin-right: 0;
}
.complete-profile {
  padding: 40px;
  max-width: 1100px;
  margin: 15px auto;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgb(0 0 0 / 9%);
}

.complete-profile h4 {
  font-size: 40px;
}

.complete-profile h5 {
  font-size: 20px;
}

.complete-profile p {
  font-size: 16px;
}
.complete-profile .profile-section {
  width: auto;
}
.complete-profile .profile-section {
  width: auto;
}
.complete-profile .login-btn button.btn_primary.btn.btn-primary {
  width: auto;
}

.notifyImg {
  max-width: 40px;
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 0;
  margin-right: 0;
  margin-left: 0;
}
.footer-social-cls {
  display: flex;
  justify-content: end;
}
.dropdown-menu[data-bs-popper] {
  left: auto;
  right: 0;
}
.box.active.serv-cont-main {
  transition: all 0.5s;
  max-height: unset;
}

.serv-cont-main {
  transition: all 0.5s;
  -webkit-line-clamp: unset !important;
  text-overflow: unset !important;
  -webkit-box-orient: unset !important;
  display: block !important;
  max-height: 117px;
  line-height: 1.6;
  text-align: justify;
}

.serv-cont-main + .btn-primary {
  margin-bottom: 10px;
}

.dropdown-toggle.btn img {
  max-width: 35px;
}
.filter-button{
  display: none;
}
.cancle-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #cf9e75;
  color: #fff;
  border-radius: 5px;
  margin-right: 6px;
}