@media (max-width: 1480px) {
  .artisan_left_img {
    flex: 0 0 140px;
  }
  .artisan_right_cont {
    flex: 0 0 calc(100% - 115px);
  }
  .artisan_price_left h5 {
    font-size: 14px;
  }
  .artisan_title h5 {
    font-size: 14px;
  }
  .artisan_title {
    margin-bottom: 0;
  }
  .artisan_img {
    padding-bottom: 100%;
  }
  .artisan_view_btn .btn_primary {
    min-width: unset;
    font-size: 12px;
    line-height: 1;
  }
  .artisan_right_cont.artisan-dtl-cont {
    padding: 15px;
  }
}
@media (max-width: 1366px) {
  .service_slider {
    flex: 0 0 58%;
    max-width: 58%;
  }
  .serv_dtl_cont {
    flex: 0 0 42%;
    max-width: 42%;
  }
  .serv-dtl-title h3 {
    font-size: 28px;
  }
  .serv_dtl_cont p {
    font-size: 13px;
  }
  span.serv_name {
    font-size: 13px;
  }
  span.check_ic {
    max-width: 15px;
  }
  .browse-artisan-left {
    flex: 0 0 300px;
    max-width: 300px;
  }

  .browse-artisan-right {
    flex: 0 0 calc(100% - 300px);
  }
}
@media (max-width: 1199.98px) {
  .account-sidebar {
    flex: 0 0 240px;
    max-width: 240px;
  }
  .account-sidebar-right {
    flex: 0 0 calc(100% - 260px);
    max-width: calc(100% - 260px);
  }
  .profile_update {
    padding: 20px;
  }
  .link-box {
    padding: 30px 10px;
  }
  .link-box h5 {
    font-size: 13px;
  }
  .became-artisan a {
    font-size: 15px;
  }
  label {
    line-height: 1.2;
    font-size: 13px;
  }
  .m-account-cls {
    flex-direction: column;
  }
  .profile_update {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }
  .category-main-div {
    margin: 0 -10px;
  }
  .category_col {
    padding: 0 10px;
  }
  .time_list_wrap {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .time_bg_wrap {
    padding: 15px;
  }
  .artisan_bg.select {
    margin-bottom: 15px;
  }
  .time_map_left {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .footer-widget {
    padding-left: 30px;
  }
  .categroy_prd_cont h4 {
    font-size: 15px !important;
  }
  .categroy_prd_cont h5 {
    font-size: 16px;
  }
  .past_serv_cont h3 {
    font-size: 14px;
  }
  .artisan_about_cont h4 {
    font-size: 24px;
  }
  .bottom-footer p {
    font-size: 12px;
  }
  .christophe_img {
    flex: 0 0 90px;
    max-width: 90px;
    padding: 20px;
  }
  .Christophe_user_name {
    margin-left: 10px;
    font-size: 14px;
  }
  .form-group.pw-show button.btn-dark {
    top: 26px;
}
}
@media (max-width: 991.98px) {
  .form-group.pw-show button.btn-dark {
    top: 26px;
  }
  .multi-select .property-bx {
    padding: 10px 10px;
  }

  .pr-name h5 {
    margin-top: 10px;
  }

  .heavy-radio-serv label.custom_radio {
    margin-bottom: 5px;
    margin-top: 0;
  }
  .order-main {
    margin: 25px 0;
  }
  .categroy_prd_cont h4 {
    margin-bottom: 0;
  }
  section.browse-artisan-sec {
    margin: 25px 0;
  }
  .wallet-top {
    flex-direction: column;
  }
  .wallet_coupon_option {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
  .wallet_left {
    width: 100%;
  }
  .wallet-history h3 {
    font-size: 18px;
  }
  .histroy-title h5 {
    font-size: 16px;
  }
  .single-history h6 {
    font-size: 13px;
  }
  .single-history .histroy-title h6 {
    font-size: 15px;
  }
  .wallet-amount h3 {
    font-size: 26px;
  }
  .serv-dtl-title h3 {
    font-size: 22px;
  }
  .service-detail-sec {
    margin: 25px 0;
  }
  .subcategory_select .checkmark {
    padding: 8px;
  }
  .subcateogry-name h5 {
    font-size: 12px;
  }
  .artisan_category_list .categroy_slider_inner {
    margin-bottom: 20px;
  }
  .subcategory-inner-list {
    margin: 0 -10px;
  }

  .single-subcategory {
    padding: 0 10px;
  }
  .aboutinner-page p {
    font-size: 13px;
  }
  .our-mission .row {
    align-items: start;
  }
  .how-we-work-sec h5 {
    font-size: 24px;
  }
  .how-we-work-sec .accordion-item button.accordion-button {
    font-size: 14px;
    padding: 10px 15px;
  }
  .how-we-work-sec .accordion-body {
    font-size: 15px;
  }
  .how-we-work-sec .accordion-item {
    margin-bottom: 10px;
  }
  .link-box {
    padding: 20px 10px;
  }
  .link-box h5 {
    font-size: 12px;
  }
  .checkout-inner {
    flex-direction: column;
  }

  .checkout-right {
    padding-left: 0;
    flex: 0 0 100%;
  }
  .category_col {
    flex: 0 0 calc(100% / 5);
  }
  .category-img{
    margin-bottom: 4px;
  }
  section.category_sec {
    margin: 10px 0;
}
  .category-img img {
    height: 50px;
  }
  .category-inner p {
    font-size: 12px;
  }
  .associated_bg {
    padding: 10px;
    border-radius: 10px;
  }
  .service-price {
    padding: 10px 0;
  }
  section.artisan-detail-sec {
    margin: 25px 0;
  }
  .service-price span {
    font-size: 13px;
  }
  .christophe_img {
    flex: 0 0 60px;
    max-width: 60px;
    padding: 10px;
  }
  .artisan_about_cont h4 {
    font-size: 20px;
  }
  .artisan_about_cont p {
    font-size: 14px;
    line-height: 1.6;
  }
  .selecttime_main {
    flex-direction: column;
  }
  .time_map_left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .time_list_wrap {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 30px;
    margin-left: 0;
    padding-left: 0;
  }
  .time_bg_wrap {
    max-height: unset;
    padding: 10px;
  }
  .footer_social ul li a img {
    max-width: 25px;
  }
  .footer-widget {
    padding-left: 0;
  }

  .list-unstyled.footer_help li {
    font-size: 11px;
  }

  .list-unstyled.footer_help li .black_bg {
    height: 20px;
    width: 20px;
    flex: 0 0 20px;
  }

  .footer-widget p {
    font-size: 12px;
  }

  .footer-widget .list-unstyled li a {
    font-size: 13px;
  }

  .footer_title span {
    font-size: 15px;
  }

  .footer_title {
    margin-bottom: 10px;
  }

  .top-footer {
    padding: 25px 0;
  }

  .bottom-footer {
    padding: 10px;
  }

  footer.footer {
    margin-top: 25px;
  }

  section.past_serv_sec {
    margin: 25px 0;
  }
  section.internel_sec {
    margin: 25px 0;
  }

  section.offer-sec {
    margin: 25px 0;
    padding: 25px 0;
  }

  section.featured_sec {
    margin: 25px 0;
  }

  .service_def {
    margin: 25px 0;
  }

  .heading_h3 {
    font-size: 18px;
  }

  .view_all a {
    font-size: 15px;
  }
  .single_serv a {
    font-size: 11px;
  }
  ul.navbar-nav li a {
    font-size: 15px;
    padding: 0 10px !important;
  }
  .white_bg .nav-item a {
    padding: 0 10px !important;
    font-size: 14px;
  }

  .btn {
    min-width: unset;
    font-size: 14px;
  }

  .order-serv-name h4 {
    font-size: 15px;
  }

  .order-price span {
    font-size: 15px;
  }
  .manage_address_inner h4 {
    font-size: 16px;
  }

  .single_address h5 {
    font-size: 16px;
  }

  .single_address p {
    font-size: 14px;
  }
  section.aboutus-main {
    margin: 25px 0;
  }
  section.artisans-sec {
    margin: 25px 0;
  }
  section.coupon-main {
    margin: 25px 0;
  }
  section.heavy-service-sec {
    margin: 25px 0;
  }
  .m_sidebar_bg {
    padding: 0;
  }
  .coupon_inner {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  
  .navbar-nav .navbar-nav + .d-flex {
    width: 100%;
  }
  .form-group.pw-show button.btn-dark {
    top: 25px;
  }
  .heavy_serv_inner {
    flex-direction: column;
  }
  .heavy-serv-cont {
    margin-top: 20px;
  }
  .dropdwon_inner.dropdown {
    width: 100%;
  }
  .navbar-nav .navbar-nav + .main-btn {
    width: 100%;
  }
  .filter_pop {
    display: flex;
    background: #ffffff;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    z-index: 111;
    border-radius: 35px;
    height: 50px;
    box-shadow: 0 0 20px hsl(0deg 0% 0% / 35%);
    padding: 10px;
    width: auto;
    min-width: 160px;
  }
  .filter_icon img {
    margin-right: 10px;
  }
  .filter_icon {
    color: #000 !important;
  }
  .sidebar_bg {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    border-radius: 0;
    z-index: 11111;
    transition: all 0.5s;
    height: 100vh;
    overflow: auto !important;
    max-width: 280px;
    padding: 0;
  }
  .open_filter .sidebar_bg {
    right: 0;
    transition: all 0.5s;
  }

  .open_filter::after {
    height: 100vh;
    background: #000000b5;
    width: 100%;
    content: "";
    position: fixed;
    z-index: 111;
    top: 0;
  }
  .open_filter::after {
    height: 100vh;
    background: #000000b5;
    width: 100%;
    content: "";
    position: fixed;
    z-index: 111;
    top: 0;
    left: 0;
  }
  .btn_cross {
    height: 30px;
    width: 30px;
    background: transparent;
    box-shadow: 0 0 20px rgb(0 0 0 / 27%) !important;
    border: 0;
    border-radius: 50%;
    margin: 10px 10px;
    padding: 0;
  }
  .filter_main {
    display: flex;
    justify-content: center;
  }
  .categroy_prd_cont {
    padding: 10px;
  }
  .service-detail {
      flex-direction: column;
  }
  .service_slider {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
  .most_active span {
    padding: 5px 10px;
    font-size: 12px;
  }
  .serv_dtl_cont {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 20px;
  }

  .video_main_sec {
    flex-direction: column;
  }

  .viedo_cont {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    margin-top: 20px;
  }
  .single-subcategory {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .subcategory-inner {
    align-items: start;
  }
  .browse-artisan-left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .apply-code-inner .black_btn {
    min-height: 30px;
    padding: 4px 10px;
  }

  .coupon-code-btn span {
    padding: 6px 10px;
    font-size: 15px;
  }
  .available_code .title_cls {
    padding: 10px 10px 0;
  }
  .coupocode-inner {
    padding: 10px;
  }
  .coupocode-inner:first-child {
    padding-top: 0;
  }
  .coupocode-inner .code_off {
    font-size: 14px;
  }
  .coupon_code {
    padding: 10px;
  }
  .coupocode-inner p {
    font-size: 12px;
    margin: 4px 0px;
  }
  .categroy_prd_cont h5 {
    font-size: 14px;
    margin-top: 0;
  }
  .artisan_about_cont p {
    font-size: 12px;
  }
  .soft_badge {
    font-size: 11px;
  }
  .our-mission .row {
    flex-direction: column-reverse;
  }

  .mission-cont {
    margin-bottom: 15px;
    margin-top: 5px;
  }
  .how-we-work-sec h5 {
    font-size: 20px;
  }
  .my-account-inner {
    flex-direction: column;
  }
  .account-sidebar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .account-sidebar {
    flex: 0 0 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .became-artisan {
    margin-bottom: 20px;
  }
  .browse-artisan-main {
    flex-direction: column;
  }
  .browse-artisan-right {
    flex: 0 0 100%;
    padding-left: 0;
    margin-top: 20px;
    width: 100%;
  }
  .sidebar_bg.active-class {
    right: 0;
}
.filter-button{
  display: block;
}
  .sort_by {
    min-width: 130px;
  }
  .category_col {
    flex: 0 0 calc(100% / 4);
  }
  .associated_bg {
    margin-bottom: 15px;
  }
  .modal-header .btn-close {
    margin-right: 0px;
  }
  .profileModalStyle {
    width: 100%;
    margin-left: auto;
  }

  .profile_img {
    height: 80px;
    width: 80px;
  }

  .profile-label {
    font-size: 13px;
  }

  .custom_checkbox .form-check label.form-check-label {
    font-size: 12px;
  }
  .forgot_pw a {
    font-size: 15px;
  }

  .already_cls p {
    font-size: 15px;
  }

  .already_cls {
    margin-top: 10px;
  }
  .form-control {
    font-size: 14px;
  }

  .btn-dark {
    font-size: 13px;
  }
  .modalStyle {
    width: 100%;
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .modal-content {
    padding: 15px;
    border-radius: 15px !important;
  }

  .modal-header {
    padding: 10px 0;
  }

  .modal-body {
    padding: 10px 0px 0;
  }

  .modal-header h5 {
    font-size: 15px;
  }

  .modal_cont h4 {
    font-size: 16px;
  }

  .modal_cont h5 {
    font-size: 20px;
  }

  .modal_cont p {
    font-size: 13px;
  }

  .owl-carousel .owl-nav div {
    width: 25px;
    height: 25px;
  }
  .copyright_cont {
    text-align: center;
    margin-bottom: 10px;
  }

  .footer_social {
    justify-content: start;
  }

  .bottom-footer p {
    font-size: 14px;
    font-weight: 500;
  }
  .footer-widget {
    margin-bottom: 20px;
  }
  .service_inner_cls {
    flex-wrap: wrap;
  }
  .single_serv {
    flex: 0 0 calc(100% / 3);
    margin-top: 10px;
  }
  .navbar-toggler.collapsed span.navbar-toggler-icon {
    filter: invert(1);
  }
  button.navbar-toggler {
    padding: 0;
  }
  .navbar-nav .navbar-nav {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    border-bottom: solid 1px #e9e9e9;
  }

  .navbar-nav .navbar-nav li.nav-item {
    display: block;
    width: 100%;
    border-bottom: solid 1px #d9d9d9;
    padding: 15px 0;
  }

  .navbar-nav .navbar-nav li.nav-item:last-child {
    border-bottom: 0;
  }

  .navbar-nav .navbar-nav li.nav-item a.nav-link {
    color: #000 !important;
    padding: 0 !important;
    font-weight: 400;
  }

  .navbar-nav .navbar-nav li.nav-item:first-child {
    padding-top: 0;
  }

  .navbar-nav .navbar-nav + .btn.main-btn {
    width: 100%;
    text-align: center;
  }
  .service-price {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .service_price_right {
    text-align: center;
    border: 0;
  }
  .service_price_left {
    border-bottom: solid 1px #707070;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
}
@media (max-width: 575.98px) {
  .main-btn.mobileBtn {
    width: 32px;
    height: 32px;
    padding: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .wallet-amount h3 {
    font-size: 20px;
  }
  .thankyou-cont h3 {
    font-size: 38px;
  }
  .thankyou-icon img {
    max-width: 60px;
  }
  .thankyou-cont p {
    font-size: 15px;
    max-width: 90%;
  }
  .serv-dtl-title h3 {
    font-size: 20px;
  }
  .single-subcategory {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .subcategory-inner {
    align-items: start;
  }
  .subcategory-inner {
    flex-direction: column;
  }
  .subcategory-right {
    flex: 0 0 100%;
    width: 100%;
    margin-top: 10px;
  }
  .subcategory-left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .subcategory-inner-list {
    width: 100%;
    margin: 0;
  }
  .verify_btn .btn_black {
    min-height: 30px;
  }
  .browse-artisan-detail .artisan_img {
    padding-bottom: 100%;
}
  .service-price span {
    font-size: 12px;
  }

  .selected_service {
    display: block;
  }
  .selected_service .continue_btn {
    margin-top: 10px;
  }
  .selected_service .continue_btn .btn_primary {
    width: 100%;
  }
  .artisan_about_cont p {
    font-size: 12px;
  }
  .artisan_right_cont.artisan-dtl-cont .artisan_title {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: start;
  }
  .artisan_right_cont.artisan-dtl-cont .artisan_title .artisan_dtl_right {
    flex: 0 0 100%;
    margin-left: 0;
    width: 100%;
    margin-bottom: 5px;
  }
  .artisan_right_cont.artisan-dtl-cont .artisan_title span.like_img {
    margin-left: auto;
    height: 25px;
    width: 25px;
  }
  .browse-artisan-detail .artisan_left_img {
    flex: 0 0 120px;
    /* margin-right: 10px; */
  }
  .artisan_left_img {
    flex: 0 0 120px;
}
  .artisan_img {
    width: 120px;
    height: 120px;
}
  .artisan_right_cont.artisan-dtl-cont {
    flex: 0 0 calc(100% - 100px);
    max-width: calc(100% - 100px);
    padding: 10px;
  }
  .artisan_dtl_inner_cont {
    padding: 5px 5px;
  }
  .artisan_dtl_inner_cont p {
    font-size: 11px;
  }
  .tag span {
    padding: 3px 10px;
    font-size: 11px;
  }
  .checkout-price-right {
    font-size: 13px;
  }
  span.checkout-price-left {
    font-size: 12px;
  }
  .other-service ul li {
    font-size: 12px;
    margin-right: 10px;
    padding: 5px 4px;
  }
  .artisan_img {
    padding-bottom: 85%;
  }
  .order-top-left h5 {
    font-size: 16px;
  }
  .artisan-order {
    padding: 10px;
    flex-wrap: wrap;
  }
  .other-service ul {
    flex-wrap: wrap;
  }

  li {
  }

  .order-top-left p {
    font-size: 13px;
  }

  .order-top-right h5 {
    font-size: 14px;
  }

  .order-art-img {
    flex: 0 0 70px;
    max-width: 70px;
  }

  .order_track_btn {
    flex: 0 0 100%;
    margin-top: 10px;
  }

  .other-service ul li {
    margin-top: 10px;
  }

  .other-service h5 {
    font-size: 15px;
  }

  .other-service {
    padding: 10px;
  }
  .order-id h5 {
    font-size: 14px;
  }
  .order-type-status {
    padding: 2px 5px;
    min-width: 70px;
    font-size: 11px;
  }

  .order-inner {
    padding: 10px;
    margin-bottom: 10px;
  }

  .btn {
    min-height: 35px;
  }

  .order-serv-name h4 {
    font-size: 14px;
  }

  .order-price span {
    font-size: 13px;
  }

  span.order-serv-type {
    font-size: 11px;
    margin-right: 5px;
    padding: 3px 10px 1px;
  }

  span.order-serv-heavy {
    font-size: 11px;
    display: flex;
    align-items: center;
  }

  .order-id {
    padding-right: 5px;
  }

  .m_sidebar_bg {
    padding: 10px;
  }
  .checkout_bg {
    padding: 10px;
    margin-bottom: 20px;
  }
  .checkout-bg {
    padding: 10px;
  }
  .checkout_selected_serv {
    padding: 10px;
  }
  .serv_desc h4 {
    font-size: 14px;
  }
  .serv_desc p {
    font-size: 13px;
  }
  .customer_select_address p {
    font-size: 15px;
  }
  .customer_select_address h3 {
    font-size: 13px;
  }
  .customer_select_address {
    padding: 10px;
  }
  .category_col {
    flex: 0 0 calc(100% / 3);
  }
  .btn_primary {
    font-size: 13px;
  }
  .owl-carousel .owl-nav div {
    width: 25px;
    height: 25px;
  }
  .serv-btn .service_btn {
    min-width: 130px;
  }
  button.heavy_service_btn {
    min-width: 130px;
  }
  span.service_btn_border {
    padding: 10px;
  }
  span.serv-btn-name {
    font-size: 14px;
  }
  .slider_banner {
    padding-bottom: 50%;
  }
  .categroy_slider_img {
    padding-bottom: 60%;
  }
  .heading_h3 {
    font-size: 16px;
  }
  section.featured_artisan_sec .categroy_slider_img {
    padding-bottom: 65%;
  }
  .footer_social {
    justify-content: center;
    margin-bottom: 10px;
  }
  .download_app {
    justify-content: center;
  }
  .download_app a:first-child {
    margin-left: 0;
  }
  .page-name {
    font-size: 14px;
  }
  .search_cls {
    max-width: 100%;
  }
  .search_form_cls input {
    font-size: 12px;
    padding-right: 40px;
    min-height: 40px;
  }
  .search-icon {
    height: 28px;
    width: 28px;
  }
  .search-icon svg {
    width: 18px;
  }
  .artisans-box h4 {
    font-size: 15px;
  }
  .artisans-box p {
    font-size: 14px;
  }
  .custom_radio {
    font-size: 12px;
    padding-left: 28px;
    margin-right: 15px;
    padding-top: 2px;
    min-width: 110px;
  }
  .radio_cls {
    flex-wrap: wrap;
  }
  .custom_radio .checkmark {
    height: 22px;
    width: 22px;
  }
  .custom_radio .checkmark:after {
    top: 4px;
    left: 4px;
  }
  .your_location_wrap h5 {
    font-size: 14px;
  }
  label {
    font-size: 13px;
  }
  .map-title h5 {
    font-size: 12px;
  }
  .map-title h6 {
    font-size: 13px;
  }
  .map-title h6 .locate_icon {
    max-width: 15px;
    margin-right: 5px;
  }
  .artisans-box {
    padding: 15px;
  }
  .banner-cont {
    padding: 75px 0 20px;
  }
  .home_cont h3 {
    font-size: 18px;
  }
  .search_inner_cls .form-select {
    font-size: 12px;
  }
}
@media (max-width: 480.98px) {
  .histroy-title h5 {
    font-size: 14px;
  }
  .single-history .histroy-title h6 {
    font-size: 12px;
  }

  .wallet-history {
    padding: 10px;
  }

  .wallet-history h3 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .single-history {
    padding: 10px 0;
  }
  .fetured_artisan_sec .categroy_slider_img {
    padding-bottom: 80%;
  }
  .profile_form .radio_cls {
    flex-direction: column;
  }
  .extrnal_links .row {
    margin: 0 -10px;
  }

  .extrnal_links .row [class*="col-"] {
    padding: 0 10px;
  }
  .extrnal_links {
    margin-top: 0;
  }
  .noti_space p {
    font-size: 13px;
  }

  .noti_time span {
    font-size: 12px;
  }
  .profile_form .radio_cls label.custom_radio {
    width: 100%;
  }
  .single_serv a {
    font-size: 10px;
    padding: 5px;
  }
  .service_main {
    margin-top: 10px;
  }
  .pr-name h5 {
    font-size: 12px;
  }
  input.postal-code.form-control {
    width: 80px;
    max-width: 80px;
  }
}
@media (max-width: 420.98px) {
  .thankyou-btn {
    flex-direction: column;
  }
  .order-btn {
    flex-direction: column;
  }
  .thankyou-cont h3 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .thankyou-cont p {
    max-width: 100%;
  }
  .thankyou_inner {
    padding: 15px;
  }
  .single-subcategory {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .category_col {
    flex: 0 0 calc(100% / 2);
  }
  .category-img img {
    height: 70px;
  }
  .category-inner p {
    font-size: 13px;
  }
  .white_bg .nav-item a {
    padding: 0 5px !important;
    font-size: 12px;
  }
}
@media (max-width: 380.98px) {
  
  .browse-artisan-detail .artisan_left_img {
    flex: 0 0 auto;
    /* margin-right: 10px; */
  }
  .artisan_bg{
display: block;
  }
  .artisan_img {
    width: 100%;
    height: 160px;
}
.artisan_img {
  padding-bottom: 45%;
}
.artisan_right_cont {
  flex-basis: 100%;
  max-width: 100%;
}
.browse-artisan-detail .artisan_img {
  padding-bottom: 0;
}
  .artisan_right_cont.artisan-dtl-cont {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width:360px){
  .single_serv {
    flex-basis: calc(100% / 2);
  }
}
